.configure-instance {
  padding: 20px 100px;
  margin: 8px;
}

.configure-instance form > div {
  margin: 0 !important;
}

.configure-instance h1 {
  font-size: 48px;
  font-weight: 500;
}

.configure-instance tr td {
  padding-bottom: 2px;
  line-height: 25px;
  margin-bottom: 6px;
}

.configure-instance tr.has-error label {
  color: #c00;
}

.configure-instance tr.has-error td input[type="text"],
.configure-instance tr.has-error td input[type="password"],
.configure-instance tr.has-error td textarea {
  border: 1px solid;
  border-color: var(--danger-color, #c4000a);
}

.configure-instance tr td .help-text {
  color: #999;
  font-size: 12px;
}

.configure-instance tr td .error-panel {
  display: none;
}

.configure-instance tr.has-error td .error-panel {
  display: block;
}

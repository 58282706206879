.jenkins-checkbox-help-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.jenkins-checkbox + a.jenkins-help-button {
  vertical-align: top;
}

.jenkins-checkbox {
  position: relative;
  display: inline-flex;
}

.jenkins-checkbox input {
  position: absolute;
  width: 1px;
  height: 1px;

  // If margin is set to a negative value it can cause text to be announced in
  // the wrong order in VoiceOver for OSX
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);

  &:not(:disabled) {
    &:active,
    &:focus {
      & + label {
        &::before {
          box-shadow:
            0 0 0 5px var(--focus-input-glow),
            inset 0 0 0 5px var(--focus-input-border);
        }
      }
    }

    &:checked {
      &:active,
      &:focus {
        & + label {
          &::before {
            box-shadow:
              0 0 0 5px var(--focus-input-glow),
              inset 0 0 0 12px var(--focus-input-border);
          }
        }
      }
    }
  }

  &:checked {
    &:active,
    &:focus {
      & + label {
        &::before {
          box-shadow:
            0 0 0 5px var(--focus-input-glow),
            inset 0 0 0 12px var(--focus-input-border);
        }
      }
    }
  }

  &:checked {
    & + label {
      &:active,
      &:focus {
        &::before {
          box-shadow:
            0 0 0 5px var(--focus-input-glow),
            inset 0 0 0 12px var(--focus-input-border);
        }
      }
    }

    & + label {
      &::before {
        box-shadow:
          0 0 0 10px transparent,
          inset 0 0 0 12px var(--focus-input-border);
      }

      &::after {
        transform: scale(1);
      }
    }
  }

  &:disabled {
    & + label {
      cursor: not-allowed;

      &::before {
        opacity: 0.35 !important;
        box-shadow:
          0 0 0 10px transparent,
          inset 0 0 0 2px var(--input-border) !important;
      }
    }

    &:checked {
      & + label {
        &::before {
          box-shadow:
            0 0 0 10px transparent,
            inset 0 0 0 12px var(--focus-input-border) !important;
        }

        &::after {
          transform: scale(1) !important;
        }
      }
    }
  }
}

.jenkins-checkbox label {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  cursor: pointer;
  line-height: 22px;
  font-weight: var(--form-label-font-weight);

  &::before {
    content: "";
    display: inline-block;
    position: relative;
    min-width: 22px;
    min-height: 22px;
    border-radius: 6px;
    transition: var(--standard-transition);
    margin-right: 11px;
    box-shadow:
      0 0 0 10px transparent,
      inset 0 0 0 2px var(--input-border);
  }

  &::after {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background: var(--background);
    mask-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='384px' height='320px' viewBox='0 0 384 320' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3ePath%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cpath d='M327.917546,10.9278525 C339.555371,-2.37251966 359.771775,-3.72027991 373.072147,7.91754577 C386.239516,19.4389932 387.692129,39.368305 376.427694,52.671077 L376.082454,53.0721475 L152.082454,309.072147 C140.014868,322.863675 118.889432,323.700972 105.767743,311.015951 L105.372583,310.627417 L9.372583,214.627417 C-3.12419433,202.13064 -3.12419433,181.86936 9.372583,169.372583 C21.7443926,157.000773 41.7261905,156.877055 54.2501999,169.001429 L54.627417,169.372583 L126.441,241.186 L327.917546,10.9278525 Z' id='Path' fill='%23FF0000' fill-rule='nonzero'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
    mask-size: 10px 10px;
    mask-repeat: no-repeat;
    mask-position: center;
    transition: var(--elastic-transition);
    transform: scale(0);
  }

  &:empty {
    &::before {
      margin-right: 0;
    }
  }

  &:hover {
    &::before {
      box-shadow:
        0 0 0 10px transparent,
        inset 0 0 0 5px var(--input-border-hover);
    }
  }

  &:active,
  &:focus {
    &::before {
      box-shadow:
        0 0 0 5px var(--focus-input-glow),
        inset 0 0 0 5px var(--focus-input-border);
    }
  }
}

.jenkins-checkbox__description {
  margin-top: 0.3rem;
  margin-left: 34px;
  margin-bottom: 1rem;
  color: var(--text-color-secondary);
  line-height: 1.66;
}

.jenkins-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  min-height: 15rem;
  background: var(--button-background);
  border-radius: 0.66rem;
  font-size: var(--font-size-base);
  margin-bottom: 30px;
  font-weight: 500;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

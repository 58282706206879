.textarea-preview-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  color: var(--text-color-secondary);
}

.textarea-preview {
  background-color: var(--very-light-grey);
  padding: var(--form-input-padding);
  margin-top: 0.5rem;
  border-radius: 10px;
}

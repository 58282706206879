.jenkins-select {
  position: relative;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 13px;
    bottom: 0;
    width: 12px;
    background-color: currentColor;
    mask-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='336px' height='192px' viewBox='0 0 336 192' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3ePath%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='arrow' transform='translate(0.000000, 0.000000)' fill='%23FF0000' fill-rule='nonzero'%3e%3cpath d='M7.02943725,7.02943725 C16.3053957,-2.24652118 31.2852799,-2.34214962 40.6788451,6.74255194 L40.9705627,7.02943725 L168,134.059 L295.029437,7.02943725 C304.305396,-2.24652118 319.28528,-2.34214962 328.678845,6.74255194 L328.970563,7.02943725 C338.246521,16.3053957 338.34215,31.2852799 329.257448,40.6788451 L328.970563,40.9705627 L184.970563,184.970563 C175.694604,194.246521 160.71472,194.34215 151.321155,185.257448 L151.029437,184.970563 L7.02943725,40.9705627 C-2.34314575,31.5979797 -2.34314575,16.4020203 7.02943725,7.02943725 Z' id='Path'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    pointer-events: none;
  }

  &__input {
    appearance: none;
    display: block;
    border: 2px solid var(--input-border);
    padding: 8px;
    width: 100% !important; // TODO remove important after https://github.com/jenkinsci/credentials-plugin/pull/255
    max-width: 100% !important; // TODO remove important after https://github.com/jenkinsci/credentials-plugin/pull/255
    border-radius: 6px;
    box-shadow: 0 0 0 10px transparent;
    transition: var(--standard-transition);
    min-height: 38px;

    &:hover {
      border-color: var(--input-border-hover);
    }

    &:active,
    &:focus {
      outline: none;
      border-color: var(--focus-input-border);
      box-shadow: 0 0 0 5px var(--focus-input-glow);
    }

    &:disabled {
      pointer-events: none;
    }
  }
}

.jenkins-multi-select {
  position: relative;
  width: 100%;
  border: 2px solid var(--input-border);
  border-radius: var(--form-input-border-radius);
  box-shadow: 0 0 0 10px transparent;
  transition: var(--standard-transition);
  outline: none;

  &:focus {
    border-color: var(--focus-input-border);
    box-shadow: 0 0 0 5px var(--focus-input-glow);
  }

  &:disabled {
    pointer-events: none;
  }
}
